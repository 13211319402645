<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" sm="6">
        <BaseCard :heading="`<span class='font-weight-bold saldo px-2 py-1'>(Saldo actual $${investSaldo})</span>`">
            <div class="mt-0">
                <v-alert
                text
                dense
                color="info"
                icon="mdi-information"
                border="left"
                class="subtitle-2"
                ><b>!Atención!</b> Monto minimo de Inversión es de {{monto_inicial}} $.</v-alert>
                <v-alert
                text
                dense
                type="error"
                border="left"
                v-if="viewError"
                class="subtitle-2"
                ><b>!Atención!</b> {{msgError}}.</v-alert>
                <v-card-text>
                    <v-text-field
                        ref="vaMonto"
                        outlined
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="va_monto"
                        :placeholder="`Mínimo ${monto_inicial}`"
                        :error="viewError"
                        @keyup="updateInversion(false)"
                        v-if="nuevo"
                    ></v-text-field>
                </v-card-text>
            </div>
            <button 
            type="button" 
            class="btn btn-rounded btn-sm btn-success w-100 subtitle-1 font-weight-medium" 
            @click="setPlan" 
            v-if="nuevo"
            :disabled="loading"
            data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i>"
            >
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                 Invertir
            </button>
            <button 
            type="button" 
            class="btn btn-rounded btn-sm btn-success w-100 subtitle-1 font-weight-medium" 
            @click="nuevaInversion" 
            v-if="!nuevo"
            >
             Nueva Inversión
            </button>
        </BaseCard>
      </v-col>
      <v-col cols="12" sm="6">
        <BaseCard heading="Calculadora de inversiones">
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
            <!-- <v-list-item-subtitle class="text-wrap">
            Use the <code>fixed-header</code> prop together with the <code>height</code> prop to fix the header to the top of the table.
            </v-list-item-subtitle> -->
            <div class="mt-0">
                <v-simple-table dense>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">Planes de inversión</th>
                        <th class="text-left">{{plan.nb_plan}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="font-weight-medium">
                        <td>Beneficio diario</td>
                        <td>${{ plan.va_diario_estimado }} <span class="subtitle-2 saldo pa-1">{{plan.va_diario}}%</span></td>
                        </tr>
                        <tr class="font-weight-medium">
                        <td>Beneficio cada {{plan.va_horas}} horas</td>
                        <td>${{ plan.va_horas_profit_estimado }} <span class="subtitle-2 saldo pa-1">{{plan.va_horas_profit}}%</span></td>
                        </tr>
                        <tr class="font-weight-medium">
                        <td>Beneficio Total</td>
                        <td>${{ plan.va_beneficio_estimado }} <span class="subtitle-2 saldo pa-1">{{plan.va_beneficio}}%</span></td>
                        </tr>
                        <tr class="font-weight-medium">
                        <td>Palzo de inversión</td>
                        <td>{{ plan.va_plazo }} días</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>
        </BaseCard>
      </v-col>
       <!-- Start Planes card -->
        <v-col cols="12" lg="12">
            <v-card>
                <v-card-text class="pa-5">
                    <span class="lstick"></span>
                    <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                    >
                    Planes de inversión
                    </h3>
                        <div class="card-body">
                            <Pricing :btnVisible="false"/>
                        </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import VsToast from '@vuesimple/vs-toast';
import Pricing from '@/components/mainComponents/pricing.vue'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'


export default {
  name: "Invertir",

  data: () => ({
    usrToken: '',
    usrData: {},
    viewError: false,
    msgError: '',
    linkrefer: 'https://ticktack.com/?refer=123456',
    plan: {},
    va_monto: null,
    monto_inicial: 0,
    id_plan: 0,
    investSaldo: 0,
    nuevo: true,
    loading: false,
    dialog: false
  }),

  computed: {
  },

  components: {
    Pricing,
    sesionExpiredDialog
  },
  methods: {
    updateInversion(onCreate) {
        //this.viewError = false
        if(!onCreate) this.viewError=false
            //this.checkMonto()
        //Obtener los datos del Calculadora de Inversión
        axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API_URL}/api/planmonto?va_monto=${this.va_monto < 20 ? this.monto_inicial : this.va_monto}`,
            headers: { 
                "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken ,
                //"Accept": "application/json"
            }
        })
        .then(async response => {
            //this.loading = false
            let respPlan = response.data
            //console.log('balance...', respPlan);
            if (!respPlan.success){
                console.error('Balance Error: ', respPlan)
            }
            else {
                this.plan = respPlan.data
                this.id_plan = respPlan.data.id
            }
        })
        .catch(error => {
            console.error('Balance Error: ', error)
            this.loading = false;
            if(error.response && error.response.statusText == "Unauthorized"){
                this.dialog = true
            }
            //let message = !error.response ? error.message : error.response.data.msg;
        })

    },

    setPlan() {
        if(!this.checkMonto()) return
        this.loading = true
        //Obtener los datos del Calculadora de Inversión
        axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API_URL}/api/users/setplanes?planes_id=${this.id_plan}&va_monto=${this.va_monto}`,
            headers: { 
                "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken ,
                //"Accept": "application/json"
            }
        })
        .then(async response => {
            //this.loading = false
            let respPlan = response.data
            //console.log('balance...', respPlan);
            if (!respPlan.success){
                VsToast.show({
                    title: 'Inversión',
                    message: 'Solicitud de inversión no puede ser procesada.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                this.msgError = respPlan.data.msg
                this.viewError = true
            }
            else {
                //console.log('Balance Succes: ', respPlan)
                this.viewError= false
                this.va_monto= null
                this.getSaldoInversion()
                VsToast.show({
                    title: 'Inversión',
                    message: 'Solicitud de inversión cargada exitosamente.',
                    variant: 'success',
                    timeout: 2000,
                    type: "alert"
                });
            }
            
            this.loading = false
            this.nuevo = false
            //this.$refs.vaMonto.focus()
        })
        .catch(error => {
            VsToast.show({
                title: 'Inversión',
                message: 'Solicitud de inversión no puede ser procesada.',
                variant: 'error',
                timeout: 2000,
                type: "alert"
            });
            console.error('Balance Error: ', error)
            this.loading = false;
            //let message = !error.response ? error.message : error.response.data.msg;
            this.msgError = (error.response && error.response.data) ? error.response.data.msg : error.message
            this.viewError = true

            this.loading = false
            //this.$refs.vaMonto.focus()
            if(error.response && error.response.statusText == "Unauthorized"){
                this.dialog = true
            }
        })

    },
    
    getSaldoInversion() {
        //Obtener los datos del Balance de Inversión
        axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/balance`,
            headers: { 
                "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken ,
                //"Accept": "application/json"
            }
        })
        .then(async response => {
            //this.loading = false
            let respPlan = response.data
            //console.log('balance...', respPlan);
            if (!respPlan.success){
                console.error('Balance Error: ', respPlan)
            }
            else {
                this.investSaldo = respPlan.data.balance.toLocaleString("es-VE")
            }
        })
        .catch(error => {
            console.error('Balance Error: ', error)
            this.loading = false;
            //let message = !error.response ? error.message : error.response.data.msg;
            if(error.response && error.response.statusText == "Unauthorized"){
                this.dialog = true
            }
        })
    },

    checkMonto() {
        //console.log('checkMonto', this.va_monto, this.monto_inicial)
        if(this.va_monto < this.monto_inicial){
            this.msgError = `El monto ingresado es inferior al minimo de inversión.`
            this.viewError = true
            this.$refs.vaMonto.focus()
            console.error(this.msgError)

            return false
        }else{
            //console.log('No es menor')
            this.viewError = false
            return true
        }

    },
    
    nuevaInversion () {
        this.nuevo = true
    },
  },
  
  async created () {
        this.usrData = await JSON.parse(localStorage.getItem("user"))
        let appConfig = await JSON.parse(localStorage.getItem("vars"))
        this.usrToken = localStorage.getItem("usrToken")
        this.monto_inicial = Number(appConfig.va_inversion_min)
        //this.monto_inicial = Number(appConfig.va_min_deposito)
        //console.log('this.appConfig: ', appConfig)
        //Obtener el saldo disponible
        this.getSaldoInversion()
        //Obtener los datos del Calculadora de Inversión
        this.updateInversion(true)
  },
  async mounted() {
    
   /*  toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-center",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "3000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    } */

  }
};
</script>

<style>
/* .primary_text input, .primary_text fieldset {
    caret-color: rgba(6,215,156, 1) !important;
    border-color: rgba(6,215,156, 1) !important;
    border-width: 2px;
    font-size: 20px;
}
.primary_text.v-input--is-focused fieldset, .primary_text.v-input--has-state fieldset {
    caret-color: rgba(6,215,156, 1) !important;
    border-color: rgba(6,215,156, 1) !important;
}
 .primary_text .v-icon {
    color: rgba(6,215,156, 1) !important;
 } */
 
/*btn classes */
.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}

.btn-primary, .btn-info, .btn-default, .btn-warning, .btn-success, .btn-danger, .btn-outline-primary:hover, .btn-check:checked+.btn-outline-primary, .btn-check:checked+.btn-outline-warning, .btn-check:checked+.btn-outline-success, .btn-check:checked+.btn-outline-danger, .btn-outline-warning:hover, .btn-outline-danger:hover, .btn-outline-info:hover, .btn-outline-success:hover, .btn-primary.active, .btn-info.active, .btn-info:focus, .btn-info:disabled, .btn-success:disabled, .btn-primary:disabled, .btn-danger.active, .btn-success.active, .btn-success:focus, .btn-danger:focus, .btn-primary:focus, .show>.btn-danger.dropdown-toggle, .show>.btn-primary.dropdown-toggle, .show>.btn-info.dropdown-toggle, .show>.btn-success.dropdown-toggle, .show>.btn-warning.dropdown-toggle {
    color: #fff;
}

.btn-success:hover{
    background-color: #e8fdeb;
    border-color: rgba(6,215,156, 1);
    color:rgba(6,215,156, 1)!important
}

.saldo{
    background-color: #e8fdeb;
    border-color: #e8fdeb;
    color:rgba(6,215,156, 1)!important

}
</style>